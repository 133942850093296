.button {
  margin-top: 10px !important;
  border-radius: 20px;
  padding: 0.5rem;
  font-weight:bolder;
  font-size:18px;
  color:#000;
  background-color:#abffaf;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

}
