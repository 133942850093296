.ImageGallery {
    margin-bottom: 5.2em;
    right: 0.35rem;
    position: absolute;
    bottom: 0;
    .card-media {
        height: 100px;
        width: 100px;
    }
    .drawer {
        margin-bottom: 1em;
    }
}
.card {
    margin-left: 1em;
    margin-right: 1em;
    padding: 4px;
    height: 180px;
    overflow-x: auto;
    .card-media {
        height: 140px;
        width: 140px;
    }
}

.down-arrow:hover{
    background-color: rgb(202, 200, 196);
    border-radius: 10px;
    cursor: pointer;
}