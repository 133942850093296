 .apexcharts-tooltip {
    color: #000000;
  }
  
   .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
    background: #d30404 !important;
  }

  .apexcharts-menu{
    background: #262525 !important;
  }

  .apexcharts-menu-item:hover {
    background: #000 !important;
}
  