.Alert{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .logo {
        position: fixed;
        left: 10px;
    }
    
    #title {
        margin: 0px;
        font-size: larger;
        align-self: center;
    }
    

    
    .toggle {
        background-color: transparent;
        outline: none;
        border: none;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
    
    .toggleLabel {
        width: 120px;
        height: 6px;
        background-color: #B0B0B0;
        border-radius: 3px;
    }
    
    p:not(#title) {
        margin: 0px 0px 5px 0px;
    }
    
    .card {
        display: flex;
        flex-direction: column;
        background-color: #fafafa;
        border-radius: 15px;
        width: 100%;
        position: fixed;
    }
    
    .show {
        padding: 10px 20px 10px 20px;
        height: 25%;
        bottom: 0px;
        z-index: 20;
        transition: height ease 0.3s;
    }
    
    .hide {
        padding: 10px 0px 0px 0px;
        background-color: #fafafa;
        height: 45px;
        bottom: 0px;
        width: 100%;
        transition: height ease 0.3s;
        overflow: hidden;
    }
    
    .details {
        color: black;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    #jobDate {
        font-size: small;
        font-weight: bold;
    }
    
    #fieldName {
        font-size: larger;
        font-weight: bold;
    }
    
    #alertId {
        font-size: larger;
        font-weight: bold;
    }
    
    #issueType {
        font-size: larger;
        font-weight: bold;
        color: red;
    }
    
    #location {
        font-size: larger;
        font-weight: bold;
    }
    
    #comment {
        font-size: larger;
        font-weight: bold;
    }
    
    .line {
        margin-top: 0.35em !important;
        right: 1rem;
        z-index: 20 !important;
        position: absolute !important;
    }
}
