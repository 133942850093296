.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:12rem;
    .login-form {
      padding-top: 10px;
      color: #FBFCFC;
      align-self: flex-start;

    }
    
  }
  .error-msg {
    color: red;
    text-align: center;
  }
}
.forgot-password{
  color:#FBFCFC;
  font-size: smaller;
  margin-left: 200px;
}

.forgot-password:hover{
  color:#BC923E;
  cursor: pointer;
}

.reset-password{
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600; 
}

// button {
//   text-align: center;
//   cursor: pointer;
//   font-size: 14px;
//   box-shadow:#BC923E;
//   font-weight: 600;
//   color: #4466F4;
//   background-color:#4C4C4C !important;
//   &:hover {
//     color: #BC923E;
//     background-color: #1A2D62;
//   }
// }


// .css-10ygm3j-MuiTypography-root-MuiLink-root{
//   color: rgb(169, 169, 219) !important;
// }
// .css-i4bv87-MuiSvgIcon-root{
//   color:grey!important;
// }