.rounded-text-box {
  border-radius: 20px;
  padding: 0.5rem;
  padding-left: 1rem;
  height: 1.2rem;
  font-size: 1rem;
  color:#000;
  background-color: #254E2D;
  font-family: var(--font-family-montserrat);
}
