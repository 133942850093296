.FarmMaps {
    margin-top: 0.35em;
    right: 0.35rem;
    z-index: 20;

    position: absolute;
    .card-media{
        height: 100px !important;
        width: 100px !important;
        cursor: pointer;
    }
}

.FarmMaps .card-media {
    height: 70px !important;
    width: 150px !important;
}

.down-arrow{
    background-color: #E8E4E0;
    border-radius: 10px;
}
.down-arrow:hover{
    background-color: rgb(202, 200, 196);
    border-radius: 10px;
    cursor: pointer;
}