.Home {
    
    .top-bar {
      // background-color: aqua;
      margin: 0.5em;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

    }
    .chip{
      margin: 0 0.75em;
    }

  }

