.CeresLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
      background-color: #254E2D;
      width: 200px;
      height: 200px;
      border-radius: 10%;
      padding: 8px;
    }
    .logo-text {
      margin-top: 8px;
      
      .work {
        color: #f3f4f5;
        font-weight: 100;
      }
      .smart {
        color: green;
        font-weight: 400;
      }
    }
  }
  