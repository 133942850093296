.Report {
  display: flex;
  justify-content: flex-end;
  margin-right: 2.5em;

  .FarmMap {
    margin-top: 2.5em;
  }

  .Sidebar {
    &.hidden {
      visibility: hidden;
    }

    &.visible {
      visibility: visible;
    }
  }

  // /* width */
  // ::-webkit-scrollbar {
  //   width: 20px;
  // }

  // /* Track */
  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 5px grey;
  //   border-radius: 10px;
  // }

  // /* Handle */
  // ::-webkit-scrollbar-thumb {
  //   background: rgb(39, 36, 36);
  //   border-radius: 10px;
  // }

  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #726d6d;
  // }
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  //background: rgb(39, 36, 36);
  background: #726d6d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #008577;
}


.side-toggle {
  position: absolute;
  left: 400px;
  top: 120px;
  border-radius: 0 10px 10px 0;
  background-color: #1D4E4A;
}

.side-toggle-closed {
  position: absolute;
  left: 0;
  top: 120px;
  border-radius: 0 10px 10px 0;
  background-color: #1D4E4A;
}

.side-toggle-2 {
  position: absolute;
  left: 400px;
  bottom: 120px;
  border-radius: 0 10px 10px 0;
  background-color: #1D4E4A;
}

// .side-toggle-closed {
//   position: absolute;
//   left:0;
//   top: 120px;
//   border-radius: 0 10px 10px 0 ;
//   background-color: #1D4E4A;
// }